<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { default as ButtonEl } from '@components/shared/Button.vue';
import TabGroup from '@components/shared/TabGroup.vue';
import SelectEl from '@components/shared/SelectEl.vue';

const store = useStore();
const emit = defineEmits([
  'profile-selected',
  'save',
  'department-selected',
  'close',
]);

const props = defineProps({
  canClose: {
    type: Boolean,
    default: false,
  },
  canSave: {
    type: Boolean,
    default: false,
  },
  multiselect: {
    type: Boolean,
    default: false,
  },
  employeeOnly: {
    type: Boolean,
    default: false,
  },
  selectedProfileType: {
    type: String,
    default: 'RESIDENT',
  },
  selectBottom: {
    type: String,
    default: 'bottom-12',
  },
  sharedDepartments: {
    type: Array,
    default: () => [],
  },
  entity: {
    type: String,
    default: 'other',
  },
  participants: {
    type: Array,
    default: () => [],
  },
  activeOptInOrOut: {
    type: String, // 'in' || 'out'
    default: null,
  },
  selectedDepartment: {
    type: Object,
    default: () => {},
  },
});

const selectedProfiles = ref([]);
const profileType = ref('');

const institutionLocale = computed(() => store.getters['institution/locale']);
const profiles = computed(() => store.getters['profiles/all']);

const selectedDepartmentWithDefault = computed(() => {
  return props.selectedDepartment || store.getters['department/current'];
});

const shownProfiles = computed(() => {
  const filteredProfiles = profiles.value
    .filter((profile) =>
      getDepartmentIds(profile).includes(
        parseInt(selectedDepartmentWithDefault.value.id)
      )
    )
    .filter((profile) => profile.type === profileType.value)
    .sort((a, b) =>
      a.firstName.localeCompare(b.firstName, institutionLocale.value)
    );

  if (!props.participants.length) {
    return filteredProfiles;
  }

  if (props.activeOptInOrOut === 'out') {
    const participatingDepartmentProfiles = filteredProfiles.filter((profile) =>
      props.participants.some((participant) => participant.id === profile.id)
    );
    return participatingDepartmentProfiles;
  }

  return filteredProfiles.filter((profile) =>
    props.participants.some((participant) => participant.id === profile.id)
  );
});

const size = computed(() => {
  return shownProfiles.value.length > 25 ? 'small' : 'large';
});

onMounted(() => {
  profileType.value = props.selectedProfileType;
});

function getDepartmentIds(profile) {
  return profile.departments.map((department) => parseInt(department.id));
}

function selectProfile(profile) {
  const profileIndex = selectedProfiles.value.indexOf(profile);

  if (profileIndex > -1) {
    selectedProfiles.value.splice(profileIndex, 1);
  } else {
    selectedProfiles.value.push(profile);
  }

  if (!props.multiselect) {
    emit('profile-selected', profile);
  }
}

function isSelected(profile) {
  return selectedProfiles.value.indexOf(profile) > -1;
}

function save() {
  if (!selectedProfiles.value.length) return;
  emit('save', selectedProfiles.value);
}
</script>

<template>
  <div class="flex flex-col mb-12">
    <div class="content flex flex-col h-full">
      <tab-group
        v-if="!employeeOnly"
        :selected-profile-type="profileType"
        @tab-selected="profileType = $event"
      />
      <div
        class="grid grid-cols-3 gap-y-10 pt-12 px-16 max-h-full mb-48"
        :class="{
          'grid-cols-3 gap-x-4': size === 'large',
          'grid-cols-5 gap-x-0': size !== 'large',
        }"
      >
        <div
          v-for="(profile, index) in shownProfiles"
          :key="index"
          class="profile flex flex-col items-center"
          :class="{ 'h-36': size !== 'large', 'h-60': size === 'large' }"
          @click="selectProfile(profile)"
        >
          <img
            class="profileImage rounded-full shadow-lg object-cover border-8 mb-4"
            :src="profile.displayImage"
            :class="[
              size,
              { 'border-success': isSelected(profile) },
              { 'border-white': !isSelected(profile) },
            ]"
          />
          <p
            class="text-2xl font-bold text-center"
            :class="{ 'text-success font-bold': isSelected(profile) }"
          >
            {{ profile.displayName }}
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="canSave || canClose"
      class="sticky bottom-0 flex flex-col items-center justify-end pt-4 pb-6 w-96 z-20 self-center"
      :class="[selectBottom]"
    >
      <button-el
        v-if="canSave"
        text="booking.confirmBooking"
        icon="check"
        :is-save="true"
        :shadow="true"
        class="w-full self-center"
        background-color="success"
        :disabled="!selectedProfiles.length"
        @click="save()"
      />

      <button-el
        v-if="canClose"
        text="global.close"
        icon="times"
        :shadow="true"
        background-color="#fff"
        text-color="#000"
        class="w-auto inline-block self-center mb-4"
        @click="emit('close')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.profile {
  &.inline {
    display: flex;
    align-items: center;
    margin-left: 40px;

    .content {
      margin-left: 20px;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  .profileImage {
    &.small {
      width: 96px;
      height: 96px;
    }

    &.large {
      width: 190px;
      height: 190px;
    }
  }
}
.select-el {
  bottom: 250px;
}
</style>
