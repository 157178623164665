import gql from 'graphql-tag';

export const bookingResourcesQuery = gql`
  query bookingResources($filter: Filter!, $timezone: TimeZone!) {
    bookingResources(filter: $filter, timezone: $timezone) {
      id
      title
      description
      authentication
      slotted
      departments {
        id
        name
        color
      }
      bookings {
        end
        id
        description
        start
        resource {
          bookings {
            end
            id
            start
          }
          coverImage {
            blurhash
            id
            source
          }
          id
          title
        }
        participants {
          ... on Employee {
            displayName
            displayImage
            showImage
            id
            departments {
              name
              color
            }
          }
          ... on Resident {
            displayName
            displayImage
            showImage
            id
            departments {
              name
              color
            }
          }
        }
      }
      coverImage {
        blurhash
        description
        id
        inMyArchive
        source
      }
    }
  }
`;
